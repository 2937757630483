import '../styles/index.scss';

import {
  Button, Carousel, Collapse, Offcanvas, Dropdown, Input, Modal, Popover, ScrollSpy, Ripple, Tab, Tooltip, Range, Alert, Animate, Datepicker, 
  Datatable, Lightbox, Navbar, Popconfirm, Rating, Sidenav, SmoothScroll, Timepicker, Toast, InfiniteScroll, LazyLoad, Stepper, Sticky, Select, 
  Touch, PerfectScrollbar, Loading, Autocomplete, Clipboard, ChipsInput, Chip, MultiRangeSlider, Datetimepicker, initMDB
} from 'mdb-ui-kit/js/mdb.es.min.js';
initMDB({
  Button, Carousel, Collapse, Offcanvas, Dropdown, Input, Modal, Popover, ScrollSpy, Ripple, Tab, Tooltip, Range, Alert, Animate, Datepicker, 
  Datatable, Lightbox, Navbar, Popconfirm, Rating, Sidenav, SmoothScroll, Timepicker, Toast, InfiniteScroll, LazyLoad, Stepper, Sticky, Select, 
  Touch, PerfectScrollbar, Loading, Autocomplete, Clipboard, ChipsInput, Chip, MultiRangeSlider, Datetimepicker
}); 

const sidenav = document.getElementById("sidenav-1");
const sidenavInstance = Sidenav.getInstance(sidenav);

let innerWidth = null;

const setMode = () => {
  // Check necessary for Android devices
  if (window.innerWidth === innerWidth) {
    return;
  }

  innerWidth = window.innerWidth;

  if (window.innerWidth < 1400) {
    sidenavInstance.changeMode("over");
    sidenavInstance.hide();
  } else {
    sidenavInstance.changeMode("side");
    sidenavInstance.show();
  }
};

setMode();

// Event listeners
window.addEventListener("resize", setMode);